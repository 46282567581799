// -- -- -- -- -- -- -- -- -- -- --
// Init the Slide Function

$( document ).ready(function() { 
    $('.modal button').on( "click", function() {
        $('.modal').hide();
    });
    $( ".navbar-toggler" ).on( "click", function() {
        $(this).toggleClass('active-btn');
      $('#nav-opener').toggleClass('d-none');
      $('#nav-closer').toggleClass('d-none').toggleClass('d-block');
      $('#navbar-brand-container .navbar-brand').toggleClass('ms-lg-0').toggleClass('me-lg-0').toggleClass('ms-lg-auto-custom');
      if ($(window).width() < 1181) {
        $('#navbar-brand-container .navbar-brand').toggleClass('d-none');
      }
      if ($(window).width() > 767) {
            $('#navbar-brand-container .navbar-wrap .first-social-element').toggleClass('d-md-none');
        }
      
    });
    
    $( "#lvip-main-navigation > li a" ).on( "click", function() {
      $( ".navbar-toggler" ).click();
      $('#nav-opener').removeClass('d-none');
      $('#nav-closer').addClass('d-none').removeClass('d-block');
    } );



        $('.lvip-usp-container').on('onscreen', function(event, measurement) {
          
            if (measurement.percentInview > 90) { 
                console.log('in view');
                $('.counter').each(function() {
                  var $this = $(this),
                      countTo = $this.attr('data-count');
                  
                  $({ countNum: $this.text()}).animate({
                    countNum: countTo
                  },
                
                  {
                
                    duration: 1500,
                    easing:'linear',
                    step: function() {
                      $this.text(Math.floor(this.countNum));
                    },
                    complete: function() {
                      $this.text(this.countNum);
                      //alert('finished');
                    }
                
                  });  
                  
                  
                
                });
            }  
          
        });
      
    
});